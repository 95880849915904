import React from 'react'

const ErrorPage = () => {
  return (
    <div>
        <div>
            <h1>Development of this page is in progress</h1>
        </div>
    </div>
  )
}

export default ErrorPage