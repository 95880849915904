export const IMG_URL1 =
  "https://i.pinimg.com/736x/e2/17/bf/e217bf02e70d91877c33cb25d46e9019.jpg";

export const Shimla_URL=
"https://i.pinimg.com/564x/0f/a9/48/0fa948b0e663115f7a42c2c0ae1896a1.jpg" ; 

export const Shimla_Itenary_URL=
"https://drive.google.com/file/d/1r-7VgWlVi3qpjZ6UMYdWlrrrPRntiLKX/view";

export const Chardham_Yatra_Itenary_URL=
"https://drive.google.com/file/d/1PNKtn-yvMKS0wT_N3VXNGYn14bR3anXy/view";

export const Jyotriling_IMG=
"https://i.pinimg.com/736x/fd/31/82/fd318227b6aae989ab11d856f54a0a2c.jpg"

export const Delhi_IMG=
"https://i.pinimg.com/736x/60/d1/aa/60d1aa36e6ad3223c2b29e615156a59e.jpg"

export const Delhi_Iternary=
"https://drive.google.com/file/d/1X1wHraIZ15PQv7Fr0QaGiURnYftiQDuV/view"

export const Jyotirlinga_Iternary=
"https://drive.google.com/file/d/19QJlrGzidJZVO52OgECgiqzIEw1ISWDt/view"

export const CharDham_Img_URL=
"https://i.pinimg.com/736x/62/ff/f5/62fff52ae3e1135687a7cb61c3f826df.jpg";

export const Kashmir_URL=
"https://i.pinimg.com/564x/e6/ab/99/e6ab99b484b2a16dafc97c84079d7364.jpg";
export const Kahmir_Itenary=
"https://drive.google.com/file/d/1-ShvMZBQq88UQ2bZXQPDOi7TooBcZXZq/view";

export const Gujarat_URL=
"https://i.pinimg.com/564x/b6/01/6a/b6016ad49284c589384194fac5ec9430.jpg";

export const Goa_URL=
"https://i.pinimg.com/564x/fe/c0/fb/fec0fb1c9a2a70f4812e3fe9ddc88d86.jpg";

export const Goa_Itenary=
"https://drive.google.com/file/d/1GmpEakO0WWFrG2yiNJmp4NduBpmapH9j/view";

export const Darjelling_URL=
"https://i.pinimg.com/564x/2d/0c/86/2d0c867e0b78617cd0ba970b4e3c4831.jpg";

export const Kerela_URL=
"https://i.pinimg.com/564x/b6/ff/32/b6ff32f7977df38b95c4782c1d0543cc.jpg";

export const VaishnoDevi_Itenary=
"https://drive.google.com/file/d/1hsTmpcKb_dh5kRaxvOzbsJijTuNAQMO_/view";
export const VaishnoDevi_IMG=
"https://i.pinimg.com/564x/3f/07/f3/3f07f3876dcbe5050bd28775a8f045c5.jpg";

export const Up_Img=
"https://i.pinimg.com/564x/ac/94/35/ac9435b1dae07a86462fa6fa691498c4.jpg";
export const Up_Itenary=
"https://drive.google.com/file/d/11WyxuJy-iJJjbIgCbkpcyt9vmn5xyyf-/view"

export const Udaipur_IMG=
"https://i.pinimg.com/564x/16/66/fd/1666fd7fc9d47153c61582bc2b0fa029.jpg";
export const Udaipur_Itenary=
"https://drive.google.com/file/d/1N05Vb2BxYyZFA6EQ6RHjKrgfDnVmQaGI/view";

export const Kerela_Itenary=
"https://drive.google.com/file/d/13mvIs_c5zWzaPgR3j8T8yyvnr_UJXQUf/view";


export const Meghalaya_Itenary=
"https://drive.google.com/file/d/1QGe--ur3RsfN0lWT68HbRpKGJAbvCAQI/view";
export const Meghalaya_IMG=
"https://i.pinimg.com/564x/4a/ea/00/4aea00a18dff0a27bc1f6013c9fca679.jpg";

export const Jim_Itenary=
"https://drive.google.com/file/d/1NIAiNu7nrVIGmEz7CmYeFggk3HROSOPZ/view"
export const Jim_Img=
"https://i.pinimg.com/564x/9a/e7/e1/9ae7e14bc932239dbebb83de85dc989b.jpg";
