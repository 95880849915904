import { createBrowserRouter } from "react-router-dom";
import MainContainer from "./MainContainer";
import ErrorPage from "./ErrorPage";
import Destination from "./Destination";
import App from "../App";
import Blog from "./Blog";
import AboutUs from "./AboutUs";

const appRouter = createBrowserRouter([
  {
    path:"/",
    element:<App />,
    children:[{
        path:"/",
        element:<MainContainer />
    },
    {
      path:"/destination",
      element:<Destination />
    },
    {
      path:"/blog",
      element:<Blog />
    },
    {
      path:"/about",
      element:<AboutUs />
    }
],
  errorElement: <ErrorPage />,
  }
])

export default appRouter;