import React from "react";
import {Link } from "react-router-dom";
import logo from "../Assets/logo.png"
const Header = ()=>{
  function handleWhatsapp() 
  {
    window.open('https://whatsform.com/6xc14b', '_blank', 'noopener,noreferrer');
    
  }
  return (
    // <div className="flex justify-between bg-white shadow-lg">
    //    <div className="logo-container">
    //     <Link to="/">
    //     <img  alt="logo" className=" h-28 w-40 " src={logo}></img>
    //     </Link>
    
    //    </div>
    //    <div className="flex items-center">
    //     <ul className="flex p-4 m-4 ">
    //       <li className="px-4"> <Link to="/">Home</Link></li>
    //       <li className="px-4"><Link to="/destination"> Destination </Link> </li>
    //       <li className="px-4"> <Link to="/blog">Blog</Link></li>
    //       <li className="px-4"> <Link to="/about"> About</Link></li>
    //     </ul>
    //     <button
    //     onClick={handleWhatsapp}
    //     className="px-5 rounded-lg py-4 mr-10 bg-[#b6ffe1]  cursor-pointer">WhatsApp</button>
    //    </div>
    // </div>
    <header class="text-gray-600 body-font shadow-lg">
  <div class="container mx-auto flex flex-wrap p-[-3.75rem] flex-col md:flex-row items-center">
    <a class="flex title-font font-medium items-center text-gray-900 ml-[-5rem] mb-4 md:mb-0">
     <Link to="/"> <img src={logo} className="h-28 ml-16 " /> </Link>
      
      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
     
      </svg> */}
      <span class="ml-3 text-xl"> <Link to="/"></Link></span>
    </a>
    <ul class="md:ml-auto flex flex-wrap items-center text-base justify-center">
    <li className="px-4"> <Link to="/">Home</Link></li>
          <li className="px-4"><Link to="/destination"> Destination </Link> </li>
           <li className="px-4"> <Link to="/blog">Blog</Link></li>
           <li className="px-4"> <Link to="/about"> About</Link></li>
    </ul>
    <img src="https://static.whatsapp.net/rsrc.php/yZ/r/JvsnINJ2CZv.svg" 
    onClick={handleWhatsapp} class="inline-flex items-center  
    cursor-pointer px-5  py-4 mr-10   border-0
     focus:outline-none hover:shadow-lg rounded text-base mt-4 md:mt-0">
      
    </img>
  </div>
</header>
  )
}
export default Header;