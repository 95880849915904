import React from 'react'
import {Shimla_URL,Kashmir_URL,Goa_URL ,Darjelling_URL,Kerela_URL,Kerela_Itenary, Meghalaya_Itenary, Goa_Itenary, Udaipur_IMG, Udaipur_Itenary, Kahmir_Itenary, Shimla_Itenary_URL} from "../utils/url"
import { useNavigate } from 'react-router-dom';

const ListContainer = () => {
  const navigate= useNavigate();

  const handleExploreDes=()=>{
   navigate("/destination");
  }

  function handleKerela(){
    window.location.href=Kerela_Itenary;
  }
  function handleMeg()
  {
    window.location.href=Meghalaya_Itenary;
  }
  function handleGoa()
  {
    window.location.href=Goa_Itenary;
  }
  function handleUdaipur()
  {
    window.location.href=Udaipur_Itenary;
  }
  function handleKahmir()
  {
    window.location.href=Kahmir_Itenary;
  }
  function handleShimla()
  {
    window.location.href=Shimla_Itenary_URL;
  }
  return (
    <div>
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Explore Destinations</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Relax 😌 while TrippyJiffy ✨ takes care of everything, crafting a memorable trip within your budget. Leave the financial worries to us</p>
    </div>
    <div class="flex flex-wrap -m-4 ">
      <div onClick={handleShimla} class="lg:w-1/3 sm:w-1/2 p-4 cursor-pointer">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center shadow-2xl" src={Shimla_URL} />
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">Shimla</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Himachal Pradesh</h1>
            <p class="leading-relaxed">Shimla, a scenic hill station, boasts colonial architecture, cool climate, and panoramic Himalayan views.</p>
          </div>
        </div>
      </div>
      <div onClick={handleKahmir} class="lg:w-1/3 sm:w-1/2 p-4 cursor-pointer">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center shadow-2xl" src={Kashmir_URL} />
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">Sonamarg</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> Kashmir</h1>
            <p class="leading-relaxed">Kashmir, renowned for stunning landscapes, offers serene lakes and majestic mountains.</p>
          </div>
        </div>
      </div>
      <div onClick={handleUdaipur} class="lg:w-1/3 sm:w-1/2 p-4 cursor-pointer">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center shadow-2xl" src={Udaipur_IMG} />
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">Udaipur</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Rajasthan</h1>
            <p class="leading-relaxed">Udaipur, the "City of Lakes," is renowned for its stunning palaces and romantic ambiance.</p>
          </div>
        </div>
      </div>
      <div onClick={handleGoa} class="lg:w-1/3 sm:w-1/2 p-4 cursor-pointer">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center shadow-2xl" src={Goa_URL} />
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">Goa</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Goa</h1>
            <p class="leading-relaxed">Goa, known for stunning beaches, lively nightlife, Portuguese architecture, vibrant festivals, and seafood cuisine.</p>
          </div>
        </div>
      </div>
      <div onClick={handleMeg} class="lg:w-1/3 sm:w-1/2 p-4 cursor-pointer">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center shadow-2xl" src={Darjelling_URL} />
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">Meghalaya</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Shillong</h1>
            <p class="leading-relaxed">Shillong is a picturesque hill station in India, known for its vibrant culture and scenic landscapes.</p>
          </div>
        </div>
      </div>
      <div onClick={handleKerela}  class="lg:w-1/3 sm:w-1/2 p-4 cursor-pointer">
        <div class="flex relative">
          <img  alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center shadow-2xl" src={Kerela_URL} />
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">Alappuzha</h2>
            <h1  class="title-font text-lg font-medium text-gray-900 mb-3">Kerala</h1>
            <p class="leading-relaxed">Alappuzha, known as Alleppey, features scenic backwaters, houseboats, and tranquil waterways in Kerala.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
 <div className='flex justify-center'>
 <button 
         className="mt-4 bg-violet-500 rounded-lg hover:bg-violet-600 text-white py-4 px-4"
         onClick={handleExploreDes}
         >
          Show More... 
        </button>
 </div>
</section>
      
     
    </div>
  )
}

export default ListContainer