import React from 'react'
import { IMG_URL1,VaishnoDevi_IMG,VaishnoDevi_Itenary,Goa_URL,Goa_Itenary, Kashmir_URL,Delhi_IMG,Delhi_Iternary, Shimla_URL ,Jyotirlinga_Iternary, Shimla_Itenary_URL ,Chardham_Yatra_Itenary_URL,CharDham_Img_URL ,Jyotriling_IMG,Up_Img,Up_Itenary ,Jim_Img,Jim_Itenary,Kerela_URL,Kerela_Itenary,Meghalaya_IMG,Meghalaya_Itenary,Udaipur_IMG,Udaipur_Itenary} from '../utils/url'
const Destination = () => {

  function handleForm(){
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdLuKhq4HnjdmXXVc0P2BDoFPKgwkkmhnXLCBmuSKaof1p4fQ/viewform', '_blank', 'noopener,noreferrer');
  }
  function handleDownload()
  {
    window.location.href="https://drive.google.com/file/d/1-ShvMZBQq88UQ2bZXQPDOi7TooBcZXZq/view"
  }
  function handleShimla()
  {
    window.location.href=Shimla_Itenary_URL;
  }
  function handleChardham()
  {
    window.location.href=Chardham_Yatra_Itenary_URL;
  }
  function handleJyoti()
  {
    window.location.href=Jyotirlinga_Iternary;
  }
  function handleDelhi()
  {
    window.location.href=Delhi_Iternary;
  } 
  function handleGoa()
  {
    window.location.href=Goa_Itenary;
  }
  function handleVaishnodevi()
  {
    window.location.href=VaishnoDevi_Itenary;
  }
  function handleUp()
  {
    window.location.href=Up_Itenary;
  }
  function handleUdai()
  {
    window.location.href=Udaipur_Itenary;
  }
  function handleKerela()
  {
    window.location.href=Kerela_Itenary;
  }
  function handleJim()
  {
    window.location.href=Jim_Itenary;
  }
  function handleMegh()
  {
    window.location.href=Meghalaya_Itenary;
  }
  return (
    <div>
    <section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4"> Himalayan Tour</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Embark on a Himalayan Tour, where you'll traverse some of the world's highest and most awe-inspiring mountain ranges. This journey offers a unique blend of adventure and serenity, with opportunities to explore ancient monasteries, vibrant local cultures, and untouched natural beauty. From the snow-capped peaks to lush valleys, crystal-clear rivers, and hidden villages, the Himalayas promise an unforgettable experience for nature lovers, trekkers, and spiritual seekers alike. Whether you're scaling a challenging summit or simply soaking in the panoramic views, the Himalayan Tour is a gateway to a world of wonders.
        </p>
        <div class="flex">
          <button onClick={handleShimla} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-36 object-cover object-center rounded shadow-2xl" src={Shimla_URL} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Kashmir</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Kashmir, often called "Paradise on Earth," is a stunning region in northern India known for its breathtaking landscapes, serene lakes, and lush valleys. Located in the Indian state of Jammu and Kashmir, it is renowned for its snow-capped mountains, vibrant gardens, and charming houseboats on Dal Lake. Key tourist destinations include Srinagar, the region's summer capital, known for its Mughal gardens and Shikara rides; Gulmarg, a premier skiing and adventure sports destination; and Pahalgam, famous for its scenic beauty and trekking routes. Visitors can also explore the beautiful valleys of Sonamarg and the ancient monasteries of Leh in the Ladakh region. Kashmir offers a unique blend of natural beauty, cultural heritage, and adventure, making it a must-visit destination for travelers.
        </p>
        <div class="flex">
          <button onClick={handleDownload} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded shadow-2xl" src={Kashmir_URL} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Chardham Yatra</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        The Chardham Yatra is a revered pilgrimage in Hinduism, consisting of visits to four sacred shrines in Uttarakhand, India. The shrines are Yamunotri, Gangotri, Kedarnath, and Badrinath. This journey, set in the majestic Himalayas, is believed to offer spiritual liberation and blessings. Pilgrims undertake the Yatra, often beginning from Yamunotri, then proceeding to Gangotri, followed by Kedarnath, and concluding at Badrinath. The Yatra is typically undertaken from May to October, avoiding harsh winter conditions. It is a transformative spiritual journey, deeply embedded in the cultural and religious fabric of India.
        </p>
        <div class="flex">
          <button onClick={handleChardham} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded shadow-2xl" src={CharDham_Img_URL} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Jyotirlingam Yatra</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        A **Jyotirlinga** is a revered symbol of Lord Shiva, embodying his boundless energy and divine presence. The term "Jyotirlinga" means "lingam of light," signifying the spiritual radiance that these sacred sites represent. According to Hindu tradition, there are 12 Jyotirlingas spread across India, each considered a highly potent manifestation of Shiva.These Jyotirlingas are believed to be self-manifested, making them unique and deeply sacred. They are central to Shiva worship and are significant pilgrimage destinations for devotees. Each Jyotirlinga has its own legend, often associated with miracles or divine interventions, adding to their spiritual significance.The 12 Jyotirlingas include well-known temples such as Somnath in Gujarat, Kedarnath in Uttarakhand, and Kashi Vishwanath in Varanasi. Pilgrims believe that visiting these Jyotirlingas can lead to spiritual enlightenment, liberation from the cycle of rebirth (moksha), and the cleansing of sins. Each temple, with its distinct history and architecture, contributes to the rich cultural and religious heritage of Hinduism.
        </p>
        <div class="flex">
          <button onClick={handleJyoti} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded shadow-2xl" src={Jyotriling_IMG} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Delhi NCR Tour</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        The Delhi-NCR (National Capital Region) is a dynamic and diverse region encompassing Delhi and its surrounding areas, including parts of Haryana, Uttar Pradesh, and Rajasthan. This expansive area offers a fascinating blend of ancient heritage and modern vibrancy. In Delhi, visitors can explore iconic landmarks like the Red Fort, Qutub Minar, and India Gate, while experiencing the city's rich cultural tapestry through bustling markets such as Chandni Chowk and serene sites like the Lotus Temple. The NCR region extends this experience with attractions like the Mughal marvel of Humayun's Tomb and the architectural splendor of the Lotus Temple. Beyond Delhi, the nearby cities of Gurgaon and Noida offer modern shopping, dining, and entertainment options, while historical sites in Agra and Jaipur are within easy reach, providing a glimpse into India’s regal past. Whether it's exploring historical forts, savoring diverse cuisines, or indulging in vibrant shopping experiences, the Delhi-NCR region presents a comprehensive and captivating experience for travelers.
        </p>
        <div class="flex">
          <button onClick={handleDelhi} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-44 object-cover object-center rounded shadow-2xl" src={Delhi_IMG} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Goa</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Goa, located on India’s southwestern coast, is renowned for its stunning beaches, vibrant nightlife, and rich Portuguese heritage. Famous for its golden sands, palm-fringed shores, and crystal-clear waters, Goa is a paradise for beach lovers and water sports enthusiasts. Beyond the beaches, visitors can explore historic churches and forts, indulge in lively markets, and savor delicious seafood and local cuisine. The region's laid-back atmosphere, combined with its cultural blend of Indian and Portuguese influences, makes Goa a popular destination for relaxation and adventure.
        </p>
        <div class="flex">
          <button onClick={handleGoa} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-44 object-cover object-center rounded shadow-2xl" src={Goa_URL} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Vaishno Devi Yatra</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Vaishno Devi is a revered Hindu pilgrimage site located in the Trikuta Mountains of Jammu and Kashmir, India. The shrine is dedicated to Goddess Vaishno Devi, an incarnation of the Hindu goddess Durga. Nestled at an elevation of approximately 5,200 feet, the temple attracts millions of devotees annually who undertake a challenging trek of about 12 kilometers from Katra to reach the holy cave. The cave temple houses a sacred naturally-formed rock formation representing the goddess. The pilgrimage to Vaishno Devi is not only a spiritual journey but also offers breathtaking views of the surrounding Himalayan landscape.
        </p>
        <div class="flex">
          <button onClick={handleVaishnodevi} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-44 object-cover object-center rounded shadow-2xl" src={VaishnoDevi_IMG} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Uttar Pradesh Tourism</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Uttar Pradesh (UP) is a state in northern India known for its rich historical, cultural, and spiritual heritage. It is home to some of the country's most iconic landmarks and destinations. The city of Agra houses the world-renowned Taj Mahal, a UNESCO World Heritage Site and a symbol of eternal love. Varanasi, one of the world's oldest cities, is a major center for Hindu spirituality and pilgrimage, famous for its ghats along the Ganges River. In Lucknow, visitors can explore the grandeur of Mughal architecture, including the Bara Imambara and the Rumi Darwaza. The state also offers significant historical sites like the ancient city of Mathura, the birthplace of Lord Krishna, and the ruins of the ancient city of Ayodhya, associated with Lord Rama. UP's diverse cultural experiences are complemented by its vibrant festivals, traditional cuisine, and bustling markets, making it a compelling destination for history enthusiasts, spiritual seekers, and travelers alike.
        </p>
        <div class="flex">
          <button onClick={handleUp} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-44 object-cover object-center rounded shadow-2xl" src={Up_Img} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Kerala Tour</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Kerala, located on the southwestern coast of India, is renowned for its picturesque landscapes, tranquil backwaters, and lush greenery. Known as "God's Own Country," Kerala features a stunning coastline, serene lakes, and rolling hills covered with tea plantations. Visitors can enjoy houseboat cruises through the backwaters of Alleppey, relax on the golden sands of Kovalam and Varkala beaches, and explore the rich cultural heritage in historic towns like Kochi and Thrissur. Kerala is also famous for its Ayurvedic treatments, vibrant festivals, and traditional art forms such as Kathakali and Mohiniyattam. Its diverse natural beauty and rich cultural traditions make it a unique and captivating destination.
        </p>
        <div class="flex">
          <button onClick={handleKerela} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-44 object-cover object-center rounded shadow-2xl" src={Kerela_URL} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Meghalaya</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Meghalaya, nestled in the northeastern part of India, is celebrated for its breathtaking landscapes and lush natural beauty. Known as the "Abode of Clouds," it features verdant hills, cascading waterfalls, and scenic valleys. Key attractions include the stunning living root bridges of Cherrapunji and Mawlynnong, the charming hill station of Shillong, and the picturesque Dawki River. Meghalaya's unique cultural heritage is reflected in its vibrant festivals and traditional dances, while its diverse flora and fauna offer numerous opportunities for nature enthusiasts. The state's serene environment and enchanting scenery make it a perfect destination for those seeking tranquility and adventure.
        </p>
        <div class="flex">
          <button onClick={handleMegh} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-44 object-cover object-center rounded shadow-2xl" src={Meghalaya_IMG} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Jim Corbett</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Jim Corbett National Park, located in Uttarakhand, India, is one of the country's oldest and most renowned wildlife reserves. Established to protect the Bengal tiger, it offers a diverse range of wildlife, including tigers, elephants, and various bird species. The park's picturesque landscapes feature dense forests, rolling hills, and riverine areas. Visitors can embark on thrilling jeep safaris and elephant rides to spot wildlife and enjoy the natural beauty of the region. Jim Corbett National Park is also known for its conservation efforts and is a popular destination for wildlife enthusiasts and nature lovers.
        </p>
        <div class="flex">
          <button onClick={handleJim} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-44 object-cover object-center rounded shadow-2xl" src={Jim_Img} />
    </div>
  </div>
</section>
<section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Udaipur</h1>
        <div class="flex mb-4">
          <a class="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
        </div>
        <p class="leading-relaxed mb-4">
        Udaipur, often called the "City of Lakes," is a picturesque city in Rajasthan, India, known for its stunning palaces, serene lakes, and rich heritage. The city is famed for its magnificent structures like the City Palace, which offers panoramic views of the city and its lakes, and Lake Pichola, where boat rides provide enchanting vistas of the surrounding architecture. Udaipur’s charming atmosphere is enhanced by its historic temples, vibrant bazaars, and traditional Rajasthani cuisine, making it a popular destination for those seeking a blend of royal history and natural beauty.
        </p>
        <div class="flex">
          <button onClick={handleUdai} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Download Iternary</button>
          <button onClick={handleForm} class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded hover:shadow-lg">Enquiry Form</button>
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-44 object-cover object-center rounded shadow-2xl" src={Udaipur_IMG} />
    </div>
  </div>
</section>
    </div>
  )
}

export default Destination