import React from 'react';
import Header_img from '../Assets/Header_img.png';
import car from '../Assets/car.webp';
import Typewriter from './TypeWriter';
import ListContainer from "./ListContainer";
import AboutUs from "./AboutUs";
import Customer from "./Customer";
import { useNavigate } from 'react-router-dom';

const MainContainer = () => {

  const navigate= useNavigate();

  const handleExploreDes=()=>{
   navigate("/destination");
  }
  return (
    <div>
      <div className="flex justify-center">
      <h1 className="mt-10 ml-8 sm:mt-12 md:mt-16 lg:mt-20 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl">
  <Typewriter text="Hello World!! Journey Through Wonders Just a Click Away" delay={100} />
</h1>



      </div>
      <div className="flex justify-center">
        <button 
         className="mt-8 bg-violet-500 rounded-lg hover:bg-violet-600 text-white py-4 px-4"
         onClick={handleExploreDes}
         >
          Explore Destinations
        </button>
      </div>
      <div className="relative w-full h-auto">
        <img alt="Header_Img" className="w-full h-full object-cover" src={Header_img} />
        {/* <img
          alt="Car"
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-10  w-32 "
          src={car}
        /> */}
      </div>
      <ListContainer />
      <AboutUs />
      <Customer />
    
    </div>
  );
};

export default MainContainer;
